import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { contentStatisticsApi } from "@/api";
import { searchKeys, columns } from "./config";
var options = {
  sorttype: [{
    id: 1,
    name: "浏览量"
  }, {
    id: 2,
    name: "点赞量"
  }, {
    id: 3,
    name: "点踩量"
  }, {
    id: 4,
    name: "回复量"
  }, {
    id: 5,
    name: "转发量"
  }],
  contenttype: [{
    id: 0,
    name: "全部"
  }, {
    id: 1,
    name: "视频"
  }, {
    id: 2,
    name: "图文"
  }, {
    id: 3,
    name: "广场快讯"
  }],
  sortvalue: [{
    id: 1,
    name: "数据从大到小"
  }, {
    id: 2,
    name: "数据从小到大"
  }]
};
export default {
  name: "contentStatistics",
  data: function data() {
    return {
      createForm: this.$form.createForm(this, {
        name: "contentStatistics"
      }),
      searchKeys: searchKeys,
      statusList: [{
        id: 1,
        name: "总计"
      }, {
        id: 2,
        name: "最近30天"
      }, {
        id: 3,
        name: "最近7天"
      }, {
        id: 4,
        name: "昨天"
      }, {
        id: 5,
        name: "今天"
      }],
      total: 0,
      page: 1,
      size: 10,
      dataSource: [],
      columns: columns,
      status: 0,
      loading: false
    };
  },
  computed: {
    getDataWithKey: function getDataWithKey() {
      return function (key) {
        return options[key] || [];
      };
    }
  },
  watch: {},
  created: function created() {
    var _this = this;
    this.$nextTick(function () {
      _this.getList();
    });
  },
  methods: {
    //查询
    handleSearch: function handleSearch() {
      this.page = 1;
      this.getList();
    },
    //重置
    handleReset: function handleReset() {
      this.status = 0;
      this.createForm.resetFields();
      this.getList();
    },
    //tab
    handleTabChange: function handleTabChange(e) {
      //   console.log("e:", e.target);
      var status = e.target.value;
      this.status = status;
      this.getList();
    },
    //内容排行统计
    getList: function getList() {
      var _this2 = this;
      this.createForm.validateFields(function (err, values) {
        if (!err) {
          // console.log("values:", values);
          _this2.loading = true;
          var params = _objectSpread(_objectSpread({}, values), {}, {
            page: _this2.page,
            size: _this2.size,
            counttype: _this2.status
          });
          contentStatisticsApi.countList(params).then(function (res) {
            console.log("dataSource:", res);
            _this2.dataSource = res.list;
            _this2.total = res.total;
            _this2.size = res.size;
            _this2.loading = false;
          }).catch(function () {
            _this2.dataSource = [];
          }).finally(function (e) {
            setTimeout(function () {
              _this2.loading = false;
            }, 3000);
          });
        }
      });
    },
    onPageSizeChange: function onPageSizeChange(page, size) {
      this.page = page;
      this.size = size;
      this.getList();
    },
    onShowSizeChange: function onShowSizeChange(page, size) {
      this.page = 1;
      this.size = size;
      this.getList();
    },
    openNewPage: function openNewPage(id, type) {
      var path = "";
      console.log(id, type);
      switch (type) {
        case "userInfo":
          path = "/user/detail?uid=".concat(id);
          break;
        case "postId":
          path = "".concat(process.env.VUE_APP_HASHMADE, "dcVideo/index?postId=").concat(id);
          break;
      }
      if (!path) {
        return;
      }
      if (type == "postId") {
        window.open(path, "_blank");
      } else {
        var routeUrl = this.$router.resolve({
          path: path
        });
        window.open(routeUrl.href, "_blank");
      }
    },
    handleTableChange: function handleTableChange(pagination, filters, sorter) {
      var field = sorter.field,
        order = sorter.order;
      var columnsIndex = this.tableColumns.findIndex(function (item) {
        return item.key == field;
      });
      // 获取上次排序并取反
      if (!order) {
        var sortOrder = this.tableColumns[columnsIndex].sortOrder;
        order = sortOrder == "descend" ? "ascend" : "descend";
      }
      columnsIndex > -1 && this.changeSortOrder(order, columnsIndex);
      this.asc = order == "descend" ? 0 : 1;
      this.getList();
    },
    changeSortOrder: function changeSortOrder(sortOrder, index) {
      this.tableColumns.forEach(function (item) {
        item.sortOrder = false;
      });
      this.tableColumns[index].sortOrder = sortOrder;
    }
  }
};