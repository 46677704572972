var searchKeys = [{
  label: '发布人Username',
  key: "username",
  placeholder: "发布人Username查询",
  required: false,
  rules: [],
  input: true
}, {
  label: '发布人昵称',
  key: "nickname",
  placeholder: "发布人昵称",
  required: false,
  rules: [],
  input: true
}, {
  label: '发布人id',
  key: "uid",
  placeholder: "发布人id",
  required: false,
  rules: [],
  input: true
}, {
  label: '排序维度',
  key: "sorttype",
  placeholder: "请选择",
  rules: [],
  select: true,
  initialValue: 1
}, {
  label: '内容类型',
  key: "contenttype",
  placeholder: "请选择",
  rules: [],
  select: true,
  initialValue: 0
}, {
  label: '排序方式',
  key: "sortvalue",
  placeholder: "请选择",
  rules: [],
  select: true,
  initialValue: 1
}, {
  key: "button",
  button: true
}];
export { searchKeys };