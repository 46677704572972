var columns = [{
  title: "内容ID",
  dataIndex: "contentid",
  key: "contentid"
}, {
  title: "内容标题",
  dataIndex: "title",
  key: "title",
  scopedSlots: {
    customRender: "postId"
  }
}, {
  title: "内容类型",
  dataIndex: "typename",
  key: "typename"
}, {
  title: "发布人",
  dataIndex: "username",
  key: "username",
  scopedSlots: {
    customRender: "username"
  }
}, {
  title: "浏览量",
  dataIndex: "views",
  key: "views"
}, {
  title: "点赞量",
  dataIndex: "upvotes",
  key: "upvotes"
}, {
  title: "点踩量",
  dataIndex: "downvotes",
  key: "downvotes"
}, {
  title: "回复量",
  dataIndex: "interactionrate",
  key: "interactionrate"
}, {
  title: "转发量",
  dataIndex: "sharing",
  key: "sharing"
}];
export { columns };